<template>
  <div>
    External
    <div v-if="isOk">
      
    
      <field-textarea :value.sync="json" name="json"/>
      <field-input :value.sync="collection" name="collection"/>
      <field-input :value.sync="doc" name="doc"/>
      <b-button @click="saveJSON">Aqui</b-button>
      
      <field-input :value.sync="collection" name="collection"/>
      <field-input :value.sync="doc" name="doc"/>
      <b-button @click="getJSON">Aqui</b-button>
      <pre>{{json}}</pre>
    </div>
    <b-button @click="logout">-</b-button>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import Bugsnag from '@bugsnag/js'

export default {
  name: 'external',
  data () {
    return {
      loggedUser: {},
      collection: '',
      doc: '',
      json: '',
      isOk: false
    }
  },
  mounted () {
    // this.getUniqueServices()
    const params = {
      ...this.$route.params
    }

    delete params.token
    delete params.module
    this.$root.$data.auth
      .signInWithCustomToken(this.$route.params.token)
      .then((data) => {
        this.$root.$data.auth.setPersistence(this.$root.$data.fb.auth.Auth.Persistence.LOCAL)
        this.loggedUser = data.user
        return data.user.getIdToken()
      })
      .then((token) => {
        this.$store.dispatch('fetchUserData', {id: this.loggedUser.uid, token, from: 'external', remember: true})
        // this.goto(this.$route.params, 'mounted')
      })
      .catch(err => {
        const routeInfo = {
          pageName: this.$route.name,
          fullPath: this.$route.fullPath,
          query: this.$route.query,
          currentUrl: window.location.href
        }

        // Reportar el error a Bugsnag con la metadata adicional
        Bugsnag.notify(err, event => {
          event.addMetadata('customLogin', {
            message: 'Error external vue',
            token: this.$route.params.token,
            errorMessage: err,
            routeInfo // Información sobre la página y la ruta desde donde se llamó
          })
        })
      })

  },
  methods: {
    // getUniqueServices () {
    //   const myArray = []
    //   services.map(el => {
    //     if (!myArray.find(el2 => el2.service === `${environment[el.base]}${el.url}`) && !['sfApiUrl', 'enviameApiUrl', 'platformUrl'].includes(el.base)) {
    //       myArray.push({ method: el.method, service: `${environment[el.base]}${el.url}`, order: `${environment[el.base]}${el.url}${el.method}`})
    //     }
    //   })
    //   myArray.sort((a, b) => a.order < b.order ? 1 : -1)
    // },
    logout() {
      this.$root.$data.auth.signOut()
        .then(resp => {
          Cookies.remove('session_id')
        })
    },
    goto (params, origin) {
      if (params.module) this.$router.push({name: params.module, params: { company: params.param1 } })
    },
    saveJSON () {
      this.$root.$data.db.collection(this.collection).doc(this.doc).set(JSON.parse(this.json))
        .then(resp => {
          this.$success('Guardado')
        })
        .catch(err => {
          this.$alert('No guardado')
        })
    },
    getJSON () {
      this.$root.$data.db.collection(this.collection).doc(this.doc).onSnapshot(resp => {
        this.json = JSON.stringify(resp.data(), undefined, 4)
      })
    }
  }
}
</script>
<style lang="">
  
</style>